import { graphql } from "gatsby";
import Img from "gatsby-image";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import Layout from "../components/Layout";
import Section from "../components/Section";

export const Title = styled.h3``;

export const Image = styled(Img)`
  margin: 30px 0;
`;

const About = ({ data }) => {
  return (
    <Layout>
      <Title>Hi!</Title>
      <Section>
        <p>
          I&apos;m Guillem, a Data Visualisation &amp; Software Engineer from
          Girona based in London.
        </p>
        <Image fluid={data.fileName.childImageSharp.fluid} alt="" />
        <p>
          I mainly use <b>JavaScript</b>, <b>React</b>, <b>D3.js</b> and{" "}
          <b>WebGL</b>. Passionate about all things Data Vis, constantly curious
          to learn new technologies, I am eager to further improve my skills and
          from time to time I enjoy working on side projects. During my free
          time, you may find me surfing or enjoying a tasty craft beer down the
          pub.
        </p>
        <p>
          This is some of the <b>tech</b> I&apos;ve worked with: D3, Mapbox,
          React, Redux, Storybook, Gatsby, Angular, Ember, Handlebars, Java,
          Spring, Struts 2, JUnit, Hibernate, RequireJS, Three.js, WebGL,
          GreenSock, jQuery, CircleCI, Netlify, Mockito, Git, GitHub, Mercurial,
          SVN, OAuth, Jira, Swing.
        </p>
        <p>
          And I worked on the integration of APIs such as: AWS, Instagram,
          Twitter, Reddit, Salesforce, Twilio, Google APIs, Firebase, SugarCRM,
          ArcGIS, Nominatim, GeoNames, Tableau, Bitfinex, Open Exchange Rates.
        </p>
        <p>
          I speak Catalan, English, Spanish, Portuguese und Ich lerne Deutsch.
        </p>
        <p>
          If you’d like to get in touch, you can send me a message at
          hello(@)guillemsegura.com.
        </p>
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query {
    fileName: file(relativePath: { eq: "profile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 250) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

About.propTypes = {
  data: PropTypes.shape({
    fileName: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object.isRequired
      }).isRequired
    }).isRequired
  })
};

export default About;
